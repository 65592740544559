import React from "react"
import PropTypes from "prop-types"

export const NewFeatureIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      style={{ width: '14px', height: '14px'}}
    >
      <path
        fill={color ?? "#00805D"}
        d="M8 11.513 12.12 14l-1.093-4.687 3.64-3.153-4.794-.407L8 1.333l-1.873 4.42-4.794.407 3.64 3.153L3.88 14 8 11.513Z"
      ></path>
    </svg>
  )
}

NewFeatureIcon.propTypes = {
  color: PropTypes.string,
}
