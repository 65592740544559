import React from "react"
import Layout from "@components/layout"
import { WhatsNew } from "@components/pages/whats-new"

const WWWWhatsNew = () => {
  return (
    <Layout
      title={`What's New`}
      description={`Klipfolio PowerMetrics' Latest Features`}
      fullWidth
      marginless
      login
    >
      <WhatsNew />
    </Layout>
  )
}

export default WWWWhatsNew
