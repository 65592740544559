import React from "react"
import PropTypes from "prop-types"

export const ImprovementIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      style={{ width: '14px', height: '14px'}}
    >
      <path
        fill={color ?? "#D24414"}
        d="M4.667 1.333v7.334h2v6l4.666-8H8.667l2.666-5.334H4.667Z"
      ></path>
    </svg>
  )
}

ImprovementIcon.propTypes = {
  color: PropTypes.string,
}
