import React from "react"
import PropTypes from "prop-types"

export const UpdateIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      style={{ width: '14px', height: '14px'}}
    >
      <path
        fill={color ?? "#343C8D"}
        d="M2.132 14v-1.125h2.437l-.28-.225a6.09 6.09 0 0 1-1.745-2.081 5.699 5.699 0 0 1-.543-2.513 5.857 5.857 0 0 1 1.171-3.572A5.954 5.954 0 0 1 6.257 2.3v1.163a4.774 4.774 0 0 0-2.269 1.809 4.84 4.84 0 0 0-.862 2.784c-.018.71.133 1.413.44 2.053a4.81 4.81 0 0 0 1.19 1.51l.563.393V9.688h1.125V14H2.132Zm7.631-.281v-1.181a4.658 4.658 0 0 0 2.269-1.81c.556-.822.85-1.792.844-2.784a4.241 4.241 0 0 0-.441-1.828 5.848 5.848 0 0 0-1.153-1.641l-.544-.487v2.325H9.613V2h4.313v1.125h-2.457l.282.263a7.382 7.382 0 0 1 1.687 2.25c.355.717.547 1.505.563 2.306a5.93 5.93 0 0 1-1.163 3.581 5.888 5.888 0 0 1-3.075 2.194Z"
      ></path>
    </svg>
  )
}

UpdateIcon.propTypes = {
  color: PropTypes.string,
}
